import PropTypes from "prop-types";

import { Section, Container, getProperty } from "@gh/shared";

import * as styles from "./map.module.scss";

export const Map = ({ data }) => {
  const src = getProperty(data, "image.publicURL");

  return (
    <Section noPaddingTop={true} noPaddingBottom={true} align="center">
      <Container>
        <img src={src} alt="" className={styles.map} />
      </Container>
    </Section>
  );
};

Map.defaultProps = {
  data: {},
};

Map.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object.isRequired,
  }),
};
