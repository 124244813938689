import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { Meta } from "./common/meta";
import { Intro } from "./apartments/intro";
import { Grid } from "./apartments/grid";
import { Map } from "./apartments/map";
import { Info } from "./apartments/info";
import { Services } from "./apartments/services";

const Template = ({ data }) => {
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.apartments.intro");
  const grid = getProperty(data, "markdownRemark.frontmatter.apartments.grid");
  const map = getProperty(data, "markdownRemark.frontmatter.apartments.map");
  const info = getProperty(data, "markdownRemark.frontmatter.apartments.info");
  const services = getProperty(data, "markdownRemark.frontmatter.apartments.services");

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <Grid data={grid} />
      <Map data={map} />
      <Info data={info} />
      <Services data={services} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        apartments {
          intro {
            title
            content
            images {
              childImageSharp {
                gatsbyImageData(width: 750, layout: CONSTRAINED)
              }
            }
          }

          grid {
            images {
              top_left {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
              top_right {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
              bottom_left {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
              bottom_right {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    height: 400
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: ENTROPY }
                  )
                }
              }
            }
            texts {
              title
              content
              banner
            }
          }

          map {
            image {
              publicURL
            }
          }

          info {
            title
            content
            lines {
              label
              value
            }
            url
          }

          services {
            title
            labels
          }
        }
      }
    }
  }
`;
