// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "info-module--button---UPx1";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "info-module--container--SzzVw";
export var image = "info-module--image--ieayH";
export var line = "info-module--line--IWdqW";
export var lines = "info-module--lines--qHaFH";
export var title = "info-module--title--Y2egR";