import PropTypes from "prop-types";

import { Section, Container, Markdown, Title, Text, /*ArrowIcon,*/ getProperty } from "@gh/shared";

import { BookButtonLarge } from "../common/book-button";

import * as styles from "./info.module.scss";

export const Info = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const lines = getProperty(data, "lines") || [];
  const url = getProperty(data, "url");

  return (
    <Section>
      <Container>
        <div className={styles.container}>
          <Title size="s8" className={styles.title}>
            {title}
          </Title>
          <Markdown content={content} />
          <div className={styles.lines}>
            {lines.map((line, index) => {
              const label = getProperty(line, "label");
              const value = getProperty(line, "value");

              return (
                <div key={index} className={styles.line}>
                  <Text size="s2">{label}</Text>
                  <Text size="s4">{value}</Text>
                </div>
              );
            })}
          </div>
          <BookButtonLarge url={url} className={styles.button} />
        </div>
      </Container>
    </Section>
  );
};

Info.defaultProps = {
  data: {},
};

Info.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    url: PropTypes.string.isRequired,
  }),
};
